import styled from '@emotion/styled';

const Ruler = styled.hr`
  height: 1px;
  width: 100%;
  background: #b5c1dc;
  border: none;
  margin: ${(props) => props.theme.modularScale.large} 0 0;
  order: 99;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    margin: ${(props) => props.theme.modularScale.xlarge} 0 0;
  }
`;

Ruler.whyDidYouRender = true;

export default Ruler;
