import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import getUrlFromCDN from '../../utils/getUrlFromCDN';

const circleStyles = () => css`
  img {
    border-radius: 9999px;
  }
`;

const StyledPicture = styled.picture`
  display: block;
  position: relative;

  img {
    height: auto;
    width: 100%;
    display: block;
  }

  ${(props) => (props.circle ? circleStyles : '')};
`;

const CMSPicture = (props) => {
  const {
    Image,
    theme,
    mime,
    provider,
    url,
    width,
    height,
    sizes,
    formats,
    disableLazyload,
    flickity,
    ...imageProps
  } = props;

  const imageSizes = formats ?? { thumbnail: { url, width } };

  // image formats size ordered keys
  const orderedSizes = ['thumbnail', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'];

  // returns an ordered key values of image sizes
  // example: { thumbnail: { url, width } }
  const sortedImageSizes = Object.keys(imageSizes)
  .sort((formatA, formatB) => orderedSizes.indexOf(formatA) - orderedSizes.indexOf(formatB))
  .reduce((accumulator, key) => {
    accumulator[key] = imageSizes[key];
    return accumulator;
  }, {});

  const getImageUrl = (imageUrl) => (
    provider === 'local'
      ? `${process.env.NEXT_PUBLIC_ASSET_HOST}${imageUrl}`
      : getUrlFromCDN(provider, imageUrl)
  );

  const srcset = () => `
    ${Object.values(sortedImageSizes)
    .map(({ url: croppedUrl, width: w }) => `${getImageUrl(croppedUrl)} ${w}w`).toString()}
  `;

  const newImageProps = {
    ...imageProps,
    src: disableLazyload ? getImageUrl(url) : process.env.NEXT_PUBLIC_LAZY_SRC,
    className: disableLazyload ? imageProps.className : `${imageProps.className || ''} lazyload`
  };

  return (
    <StyledPicture {...props}>
      <source
        type={mime}
        srcSet={disableLazyload && !flickity ? srcset() : undefined}
        data-srcset={disableLazyload || flickity ? undefined : srcset()}
        data-flickity-lazyload-srcset={flickity ? srcset() : undefined}
        sizes={sizes}
      />
      <Image
        {...newImageProps}
        data-src={disableLazyload || flickity ? undefined : getImageUrl(url)}
        // browsers aspect ratio hint
        width={width}
        height={height}
      />
    </StyledPicture>
  );
};

CMSPicture.defaultProps = {
  sizes: '100vw',
  Image: styled.img``,
  formats: undefined,
  circle: false,
  flickity: false,
  disableLazyload: false,
  theme: {}
};

CMSPicture.propTypes = {
  url: PropTypes.string.isRequired,
  mime: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  sizes: PropTypes.string,
  Image: PropTypes.elementType,
  formats: PropTypes.shape(),
  circle: PropTypes.bool,
  flickity: PropTypes.bool,
  disableLazyload: PropTypes.bool,
  theme: PropTypes.shape()
};

export default withTheme(CMSPicture);
