import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Link from '../../utils/link';
import Typography from '../../atoms/typography';
import Picture from '../../atoms/cms-picture';
import Ruler from '../../atoms/ruler';

const PromoBannerWrapper = styled.section`
`;

const Headline = styled(Typography)`
  color: ${(props) => props.theme.color.brandA11yRed};
  text-align: center;
`;

const PromosWrapper = styled.div`
  display: grid;
  row-gap: 1rem;
`;

const Promo = styled.div``;

const PromoBannerImageDesktop = styled(Picture)`
  display: none;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
`;

const PromoBannerImageMobile = styled(Picture)`
  display: block;
  margin: 0 auto;
  max-width: 100%;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
    display: none;
  }
`;

const ImageAnchor = styled.a`
  width: 100%;
`;

const PromoBanner = (props) => {
  const renderImages = (element) => {
    // TODO: handle images
    if (element.image_desktop && element.image_mobile) {
      return (
        <>
          <PromoBannerImageDesktop
            {...element.image_desktop}
            alt={element.image_desktop_alt}
          />
          <PromoBannerImageMobile
            {...element.image_mobile}
            alt={element.image_mobile_alt}
          />
        </>
      );
    }
    return null;
  };

  return (
    <PromoBannerWrapper>
      {
        props?.data?.headline
        && (
          <Headline element="h2" like="heading-4">
            {props.data.headline}
          </Headline>
        )
      }

      <PromosWrapper>
        {
          props?.data?.elements?.length > 0
            && props.data.elements
              .map((element) => (
                <Promo key={`promo-banner-${element.id}`}>
                  {element.url
                    ? (
                      <Link
                        href={`${element.url}?td=promo-banner-module`}
                        passHref
                      >
                        <ImageAnchor>
                          {renderImages(element)}
                        </ImageAnchor>
                      </Link>
                    )
                    : renderImages(element)}
                </Promo>
              ))
        }
      </PromosWrapper>

      {props?.data?.hr && <Ruler />}

    </PromoBannerWrapper>
  );
};

PromoBanner.propTypes = {
  data: PropTypes.object.isRequired
};

PromoBanner.whyDidYouRender = true;

export default PromoBanner;
